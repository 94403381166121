<script setup>
  import { MainButton, useWebAppPopup, useWebApp } from 'vue-tg'
  const { showAlert } = useWebAppPopup()

  const username = useWebApp().initDataUnsafe.user.username;
  const mainButtonText = 'Click on me ' + username;
  const mainButtonAlertText = 'How are you ' + username + '?';
</script>

<template>
  <div class="hello">
    <h1>{{ msg }}</h1>

    <MainButton :text="mainButtonText" @click="() => showAlert(mainButtonAlertText)" />
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
